<template>
  <v-tabs>
    <v-tab>Таблица</v-tab>
    <v-tab>Пример</v-tab>
    <v-tab-item>
      <BaseGeoJsonExample
        class="mt-1"
        v-if="geoJsonExample"
        :pre-geo-json="geoJsonExample"
      />
    </v-tab-item>
    <v-tab-item>
      <BaseCodeBlock :code="geoJson"/>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import BaseGeoJsonExample from '@/components/base/BaseGeoJsonExample'
import BaseCodeBlock from '@/components/base/UI/BaseCodeBlock'
import { filterAttributes, flatenObject, cleanClone } from '@/components/utils/common'
import testDataPassport from '@/components/views/account/passport/common/testDataPassport'
import testDataRegion from '@/components/views/account/passport/common/testDataPassport'

export default {
  name: 'BaseUploaderTabs',
  components: { BaseCodeBlock, BaseGeoJsonExample },
  props: {
    passportEntity: Array
  },
  data () {
    return {
      geoJson: {
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326"
          }
        },
        features: [{
          type: 'feature',
          geometry: {
            type: '',
            coordinates: []
          },
          properties: {}
        }]
      },
      geoJsonExample: null
    }
  },
  methods: {
    createGeoJsonObject () {
      const filteredPassport = flatenObject(filterAttributes(this.geoJsonExample, ['geometry', 'latitude', 'longitude', 'id', 'state']), 'relatedAttributes')
      return filteredPassport.forEach((item) => {
        if ((item.eavType.id !== 9 && item.eavType.id !== 14)) {
          this.geoJson.features[0].properties[item.name] = item.label
        }
      })
    },
    async setGeoJsonExample () {
      if (this.passportEntity) {
        this.geoJsonExample = cleanClone(this.passportEntity)
      } else if (this.$route.name === 'region') {
        this.geoJsonExample = testDataRegion
      } else {
        this.geoJsonExample = testDataPassport
      }
      this.createGeoJsonObject()
    }
  },
  mounted () {
    this.setGeoJsonExample()
  }
}
</script>

<style scoped>

</style>